import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b2a2790"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "sub-text-s3 text-gray-second"
}
const _hoisted_2 = {
  key: 1,
  class: "opened"
}
const _hoisted_3 = { class: "sub-text-s3" }
const _hoisted_4 = {
  key: 2,
  class: "reserved"
}
const _hoisted_5 = { class: "reserved sub-text-s3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["date-cell", {
      'not-current__month': _ctx.state.isNotCurrentMonth,
      today: _ctx.state.isToday,
    }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.actions.goToDetail()))
  }, [
    _createElementVNode("span", null, _toDisplayString(_ctx.state.day), 1),
    (_ctx.state.isToday)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, " today "))
      : _createCommentVNode("", true),
    (!!_ctx.scheduleInfo)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.scheduleInfo.openedCount), 1)
        ]))
      : _createCommentVNode("", true),
    (!!_ctx.scheduleInfo && _ctx.scheduleInfo.reservedCount > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.scheduleInfo.reservedCount), 1)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}