
import { computed, defineComponent, onMounted, PropType, reactive } from "vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import CardBasic from "@/components/console/cards/CardBasic.vue";
import moment from "moment-timezone";
import { Moment } from "moment-timezone";
import LabsYoil from "@/pages/console/Labs/CalendarComponents/LabsYoil.vue";
import LabsMonthDate from "@/pages/console/Labs/CalendarComponents/LabsMonthDate.vue";

type CalendarType = "M" | "W";
type MomentArr = Moment[];
type TimeGrid = {
  startTime: Moment;
  endTime: Moment;
};
type TimeList = TimeGrid[];

type ScheduleObj = {
  startTime: Moment;
  endTime: Moment;
};
export default defineComponent({
  name: "LabsCalendar",
  components: { LabsMonthDate, LabsYoil, CardBasic, ButtonBasic },
  props: {
    calendarType: {
      type: String as PropType<CalendarType>,
      default: "M",
    },
    schedules: {
      type: Array as PropType<ScheduleObj[]>,
      default: () => {
        return [];
      },
    },
  },
  setup(props) {
    const monthType = "M";
    const weekType = "W";
    const yoil: number[] = [0, 1, 2, 3, 4, 5, 6];

    const state = reactive({
      completeCalendarSet: false,
      calendarType: props.calendarType as CalendarType,
      monthTypeCalendar: [
        [null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null],
      ] as MomentArr[],
      weekTypeGrid: [null, null, null, null, null, null, null] as MomentArr,
      timeList: [] as TimeList[],
      month: computed(() => {
        return moment().add(state.monthIndex, "months").month() + 1; // note moment.month() 는 index 0 부터시작, 현재 월을 얻으려면 +1 해야함.
      }),
      monthIndex: 0,
      weekIndex: 0,
      isMouseDown: false,
      selectRangeStart: null as Moment,
      selectRangeEnd: null as Moment,
      isSelectedRange: computed(() => {
        return !!state.selectRangeStart && !!state.selectRangeEnd;
      }),
    });

    onMounted(() => {
      setMonthCalendar(0);
      state.completeCalendarSet = true;
      setTimeList();
    });

    const setTimeList = (): void => {
      for (let i = 0; i < 48; i++) {
        state.timeList.push([null, null, null, null, null, null, null]);
      }
    };

    const setMonthCalendar = (index: number): void => {
      const beforeMonthLastDate = moment()
        .add(index - 1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
      console.log(beforeMonthLastDate);

      const startDayOfMonth = moment()
        .add(index, "months")
        .startOf("month")
        .day(); // note 현재 달의 첫일자의 요일 (0:일 ~ 6:토)

      const startDateFormat = moment()
        .add(index, "months")
        .startOf("month")
        .format("YYYY-MM-DD");

      let count = 0;
      for (let i = 0; i < 6; i++) {
        for (let j = 0; j < 7; j++) {
          if (i === 0 && j < startDayOfMonth) {
            state.monthTypeCalendar[i][j] = moment(beforeMonthLastDate).add(
              -j,
              "days"
            );
          } else {
            state.monthTypeCalendar[i][j] = moment(startDateFormat).add(
              count,
              "days"
            );
            count++;
          }
        }
      }
    };

    const setWeekCalendar = (index: number): void => {
      const weekStartDateFormat = moment()
        .add(index - 3, "days")
        .format("YYYY-MM-DD");

      for (let i = 0; i < 7; i++) {
        state.weekTypeGrid[i] = moment(weekStartDateFormat).add(i, "days");
      }

      const weekStartTime = moment(weekStartDateFormat)
        .startOf("day")
        .format("YYYY-MM-DD hh:mm");

      for (let i = 0; i < 48; i++) {
        for (let j = 0; j < 7; j++) {
          state.timeList[i][j] = {
            startTime: moment(weekStartTime)
              .add(j, "days")
              .add(i * 30, "minutes"),
            endTime: moment(weekStartTime)
              .add(j, "days")
              .add((i + 1) * 30, "minutes"),
          };
        }
      }
    };

    const resetIndex = (): void => {
      state.weekIndex = 0;
      state.monthIndex = 0;
    };

    const isIncludeDate = (timeGrid: TimeGrid): boolean => {
      if (state.isSelectedRange) {
        return (
          state.selectRangeStart.diff(timeGrid.startTime) <= 0 &&
          state.selectRangeEnd.diff(timeGrid.endTime) >= 0
        );
      } else {
        return false;
      }
    };

    const actions = {
      test: () => {
        console.log(moment().startOf("month").format("YYYY-MM-DD"));
      },
      setMonthType: (index = 0): void => {
        state.calendarType = monthType;
        resetIndex();
        setMonthCalendar(index);
      },
      setWeekType: (index = 0): void => {
        state.completeCalendarSet = false;
        state.calendarType = weekType;
        resetIndex();
        setWeekCalendar(index);
        state.completeCalendarSet = true;
      },
      setWeekCalendarForDate: (date: Moment) => {
        state.weekIndex = date.diff(moment(), "days");
        state.monthIndex = date.month() - moment().month();
        setWeekCalendar(state.weekIndex);
        state.calendarType = weekType;
      },
      onMousedownEvent: (e, timeGrid: TimeGrid) => {
        if (!isIncludeDate(timeGrid)) {
          state.isMouseDown = true;
          state.selectRangeStart = timeGrid.startTime;
          state.selectRangeEnd = timeGrid.endTime;
        }
      },
      onMouseUpEvent: (e, timeGrid: TimeGrid) => {
        state.selectRangeEnd = timeGrid.endTime;
        state.isMouseDown = false;
      },
      onMouseEnterEvent: (e, timeGrid: TimeGrid): void => {
        if (state.isMouseDown) {
          state.selectRangeEnd = timeGrid.endTime;
        }
      },
    };
    return { state, actions, monthType, weekType, yoil, isIncludeDate };
  },
});
