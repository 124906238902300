
import { computed, defineComponent, PropType, reactive } from "vue";
import moment, { Moment } from "moment-timezone";

type ReservedDate = {
  openedCount: number;
  reservedCount: number;
};
export default defineComponent({
  name: "LabsMonthDate",
  props: {
    date: {
      type: [Object, null] as PropType<Moment>,
      required: true,
    },
    selectedMonth: {
      type: Number as PropType<number>,
      required: true,
    },
    scheduleInfo: {
      type: [Object, null] as PropType<ReservedDate>,
      default: null,
    },
  },
  emits: ["toggleWeekCalendar"],
  setup(props, { emit }) {
    const state = reactive({
      day: computed(() => {
        return props.date ? props.date.date() : "";
      }),
      isNotCurrentMonth: computed(() => {
        return props.date
          ? props.selectedMonth !== props.date.month() + 1
          : false;
      }),
      isToday: computed(() => {
        return (
          props.date.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
        );
      }),
    });

    const actions = {
      goToDetail: () => {
        emit("toggleWeekCalendar");
      },
    };

    return { state, actions };
  },
});
